<template>
  <h2>會員管理</h2>

  <div class="my-4 text-end">
    <router-link :to="`/admin/member`">返回列表頁</router-link>
  </div>

  <div class="card mb-5">
    <div class="card-header">
      <div class="h5 d-inline mb-0">查看會員</div>
      <!-- <button type="reset" class="btn btn-primary float-end">清除重填</button> -->
    </div>
    <div class="card-body">
      <div class="row" v-if="is_member_base_data_fetched">
        <!-- <div class="col-md-6">??</div>
              <div class="col-md-6">??</div> -->

        <div
          class="alert alert-danger"
          role="alert"
          v-if="data.status && data.status === 'delete'"
        >
          <i class="bi bi-exclamation-triangle"></i>
          該會員已經是停用的狀態
        </div>

        <div class="col-md-12">
          <div class="mb-4">
            <label class="form-label">經紀人身份</label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.agent">是</div>
              <div v-else>否</div>
            </div>
          </div>
        </div>

        <div class="col-md-12" v-if="data.photoURL">
          <div class="mb-3">
            <label class="form-label"> 會員頭像 </label>
            <div>
              <img :src="data.photoURL" class="img-thumbnail memberImg" />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">會員帳號</label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.member_id">{{ data.member_id }}</div>
              <div class="text-danger" v-else>無資料</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label"> 暱稱 </label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.name">{{ data.name }}</div>
              <div class="text-danger" v-else>無資料</div>
            </div>
          </div>
        </div>
        <!--<div class="col-md-6">
            <div class="mb-3">
              <label class="form-label"> 會員編號 (測試用, 先亂產生) </label>
              <div class="bg-light px-3 py-2 rounded" role="alert">
                {{ memberId }}
              </div>
            </div>
          </div>-->

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label"> 上線會員 </label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <router-link
                :to="`/admin/member/view/${data.parentMemberId}`"
                target="_blank"
                v-if="data.parentMemberId"
              >
                {{ parentMemberData.name }}
              </router-link>
              <div class="text-danger" v-else>無上線</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">生日</label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.birthday">
                {{ data.birthday.toDate().toLocaleDateString() }}
              </div>
              <div class="text-danger" v-else>無填資料</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">
              <span class="text-danger">*</span>
              手機號碼 (格式請以 +8869xxxxxxxx)
            </label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.mobile">{{ data.mobile }}</div>
              <div class="text-danger" v-else>無資料</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">電子信箱</label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.email">{{ data.email }}</div>
              <div class="text-danger" v-else>無資料</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">服務店家</label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.store">{{ data.store }}</div>
              <div class="text-danger" v-else>無資料</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">編號藝名</label>
            <div class="bg-light px-3 py-2 rounded" role="alert">
              <div v-if="data.numberStageName">{{ data.numberStageName }}</div>
              <div class="text-danger" v-else>無資料</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <div class="h5 d-inline mb-0">本月收入</div>
          <div class="float-end">
            <span class="me-4">
              歷史累積分潤：
              <span v-if="data.totalBonus">{{ data.totalBonus }}</span>
              <span v-else>0</span>
            </span>
            <span>
              G幣餘額：
              <span v-if="data.balance">{{ data.balance }}</span>
              <span v-else>0</span>
            </span>
          </div>
        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item mb-4">
              個人經紀費：
              <span v-if="bonusDetail.brokerageFee">{{
                bonusDetail.brokerageFee
              }}</span>
              <span v-else>0</span>
            </li>
            <!-- <li class="list-group-item">
                個人分潤({{ bonusDetail.selfBonusPercentage }}%)：{{
                  bonusDetail.selfBonusDetail
                }}
              </li> -->
            <li class="list-group-item">
              第一代下線總分潤(<span v-if="bonusDetail.Level1bonuspercentage"
                >{{ bonusDetail.Level1bonuspercentage }} %</span
              ><span v-else>-</span>)：
              <span v-if="bonusDetail.L1BonusDetailTotal">{{
                bonusDetail.L1BonusDetailTotal
              }}</span>
              <span v-else>0</span>
            </li>
            <li class="list-group-item">
              第二代下線總分潤(<span v-if="bonusDetail.Level2bonuspercentage"
                >{{ bonusDetail.Level2bonuspercentage }} %</span
              ><span v-else>-</span>)：
              <span v-if="bonusDetail.L2BonusDetailTotal">{{
                bonusDetail.L2BonusDetailTotal
              }}</span>
              <span v-else>0</span>
            </li>
            <li class="list-group-item">
              第三代下線總分潤(<span v-if="bonusDetail.Level3bonuspercentage"
                >{{ bonusDetail.Level3bonuspercentage }} %</span
              ><span v-else>-</span>)：
              <span v-if="bonusDetail.L3BonusDetailTotal">{{
                bonusDetail.L3BonusDetailTotal
              }}</span>
              <span v-else>0</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-7">
      <div class="card">
        <div class="card-header h5">
          <div class="h5 d-inline mb-0">G幣明細紀錄</div>
        </div>
        <div class="card-body recordBody px-0 py-0">
          <table class="table table-hover">
            <thead class="sticky-top bg-light">
              <tr>
                <th>序號</th>
                <th>G幣金額</th>
                <th>交易類型</th>
                <th>交易時間</th>
                <th>備註</th>
                <th>作業人員</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in transactionDatas" :key="index">
                <td width="50">{{ index + 1 }}</td>
                <td width="150" :class="transaction.data.doType">
                  <span v-if="transaction.data.doType === 'out'">-</span
                  >{{ transaction.data.bonus }}
                </td>
                <td width="150" :class="transaction.data.doType">
                  {{ transaction.data.transactionType }}
                </td>
                <td width="100">
                  {{ transaction.data.entryTime.toDate().toLocaleString() }}
                </td>
                <td>{{ transaction.data.remark }}</td>
                <td width="150">{{ transaction.data.creator }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="h5 d-inline mb-0">團隊總覽</div>
        </div>
        <div class="card-body" id="orgCardBody">
          <OrgTree :tree="memberTree"></OrgTree>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <div class="h5 d-inline mb-0">本月紀錄</div>
        </div>
        <div class="card-body recordBody">
          <div class="row">
            <div class="col">
              <div class="h6">打卡記錄</div>
              <div v-if="checkIn.length > 0">
                <span class="badge bg-danger mb-2">{{ checkIn.length }}次</span>
                <ul>
                  <li v-for="(c, cindex) in checkIn" :key="cindex">
                    {{ c.toDate().toLocaleDateString() }}
                  </li>
                </ul>
              </div>
              <div v-else-if="checkIn === false">
                <p class="fst-italic">無打卡記錄</p>
              </div>
            </div>
            <!-- <div class="col">
                <div class="h6">求救記錄</div>
                <div>xxx</div>
                <div>xxx</div>
                <div>xxx</div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="h5 d-inline mb-0">指定分潤比例</div>
            <div class="float-end">
              <i class="bi bi-exclamation-square-fill"></i>
              已結算分潤將不受影響
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label class="form-label">第一代 下限分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.Level1bonuspercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第二代 下限分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.Level2bonuspercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第三代 下限分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.Level3bonuspercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb">
              <label class="form-label">自身分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.selfBonusPercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  <div class="my-5 text-center">
    <router-link :to="`/admin/member`">返回列表頁</router-link>
  </div>
</template>

<script>
import { memberService } from "../../../services/memberService.js";
import OrgTree from "@/components/OrgTree.vue";
import { Modal } from "bootstrap";
export default {
  components: {
    OrgTree,
  },
  data() {
    return {
      parentMemberOption: [],
      memberId: "",
      data: {},
      myModal: null,
      parentMemberData: {},
      transaction: {
        transactionDoType: "in", // 手工G幣存提 in , out
        transactionBonus: "",
        transactionBonusPlaceholder: {
          in: "請輸入欲存入的金額",
          out: "請輸入欲提出的金額",
        },
        transactionTypeOptions: {
          in: ["車資補貼", "生日禮金", "活動獎金", "其他存入"],
          out: ["G幣兌現", "修正扣回", "其他提取"],
        },
        transactionTypeSelected: "",
        transactionRemark: "",
      },

      transactionDatas: [],

      showParentMemberOption: true,
      is_member_base_data_fetched: false,
      memberTree: [],
      bonusDetail: {},
      checkIn: [],
    };
  },
  methods: {
    async updateMember() {
      const result = await memberService.updateMember(this.memberId, this.data);

      alert(result.msg);

      if (result.res) {
        this.$router.push({ name: "member" });
      }
    },
    showModal() {
      this.myModal = new Modal(document.getElementById("exampleModal"), {});
      this.myModal.show();
    },
    hideModal() {
      this.myModal.hide();
    },
    async transactionSubmit() {
      if (confirm("是否確定資訊都正確無誤？")) {
        const res = await memberService.transaction(
          this.transaction,
          this.memberId
        );
        // console.log(res);
        alert(res.msg);
        if (res.result === 1) {
          this.myModal.hide();
          this.transaction.transactionDoType = "in";
          this.transaction.transactionBonus = "";
          this.transaction.transactionTypeSelected = "";
          this.transaction.transactionRemark = "";

          this.data.balance = res.balance;
          this.data.totalBonus = res.totalBonus;

          // 手動存提G幣之後，直接把新增結果放到transactionDatas陣列的最前面顯示
          this.transactionDatas.unshift({ data: res.trans });

          // console.log(this.transactionDatas);
        }
      }
    },
    async getTransactionDatas() {
      this.transactionDatas = await memberService.getMemberTransaction(
        this.memberId
      );
      // console.log(this.transactionDatas);
    },
  },
  async created() {
    const member = await memberService.getOneMember(
      this.$route.params.memberId
    );

    if (!member) {
      alert("查無此會員");
      this.$router.push({ name: "member" });
    } else {
      this.data = member.data;
      this.memberId = member.id;

      const hasChild = await memberService.hasChild(this.memberId);
      if (hasChild === true) {
        this.showParentMemberOption = false;
      } else {
        this.showParentMemberOption = true;
        // 取得會員名單
        this.parentMemberOption =
          await memberService.getMembersSelectOptions_withOutOne(this.memberId);
      }

      if (this.data["parentMemberId"]) {
        const parentMemberData = await memberService.getOneMember(
          this.data["parentMemberId"]
        );
        this.parentMemberData = parentMemberData.data;
      }

      this.is_member_base_data_fetched = true;

      // 取得團隊總覽
      this.memberTree = await memberService.getTreeMember(this.memberId);

      const now = new Date();

      // 取得本月分潤
      this.bonusDetail = await memberService.getMemberBonusDetail(
        this.memberId,
        now.getFullYear(),
        now.getMonth() + 1
      );

      // 取得本月打卡記錄
      this.checkIn = await memberService.getMemberCheckIn(
        this.memberId,
        now.getFullYear(),
        now.getMonth() + 1
      );

      this.getTransactionDatas();

      // this.showModal(); //test
    }
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

td.in {
  color: #198754;
}

td.out {
  color: #dc3545;
}

#orgCardBody {
  overflow-x: auto;
}

.memberImg {
  max-height: 250px;
}

.recordBody {
  max-height: 245px;
  overflow-x: auto;
}
</style>
